import Gen from "@helper/Gen"
var fo_router = [
    {
        name: "index",
        path: "",
        component: () => import("./frontend/Home.vue"),
    },
    {
        name: "AboutUs",
        path: "tentang-farmsco",
        component: () => import("./frontend/AboutUs.vue"),
    },
    {
        name: "CustomerActivities",
        path: "customer-activities",
        component: () => import("./frontend/CustomerActivities.vue"),
    },
    {
        name: "DetailTags",
        path: "detail-tags/:slug",
        component: () => import("./frontend/DetailTags.vue"),
    },
    {
        name: "SearchPage",
        path: "search/:q",
        component: () => import("./frontend/SearchPage.vue"),
    },
    {
        name: "DetailCustomerActivities",
        path: "customer-activities/:slug",
        component: () => import("./frontend/DetailCustomerActivities.vue"),
    },
    {
        name: "DirectMail",
        path: "direct-mail",
        component: () => import("./frontend/DirectMail.vue"),
    },
    {
        name: "DirectMailDetail",
        path: "direct-mail/:slug",
        component: () => import("./frontend/DirectMailDetail.vue"),
    },
    {
        name: "Jurnal",
        path: "jurnal",
        component: () => import("./frontend/Jurnal.vue"),
    },
    {
        name: "JurnalDetail",
        path: "jurnal/:slug",
        component: () => import("./frontend/JurnalDetail.vue"),
    },
    {
        name: "Artikel",
        path: "artikel",
        component: () => import("./frontend/Artikel.vue"),
    },
    {
        name: "ArticleDetail",
        path: "artikel/:slug",
        component: () => import("./frontend/ArticleDetail.vue"),
    },
    {
        name: "Galeri",
        path: "galeri",
        component: () => import("./frontend/Galeri.vue"),
    },
    {
        name: "Contact",
        path: "kontak-kami",
        component: () => import("./frontend/Contact.vue"),
    },
    {
        name: "Toc",
        path: "syarat-ketentuan",
        component: () => import("./frontend/Toc.vue"),
    },
    {
        name: "Privacy",
        path: "kebijakan-privasi",
        component: () => import("./frontend/Privacy.vue"),
    },
    {
        name: "ProductList",
        path: "produk",
        component: () => import("./frontend/ProductList.vue"),
    },
    {
        name: "Product",
        path: "produk/:slug/:detail?",
        component: () => import("./frontend/Product.vue"),
    },
    // {
    //     name: "DetailProduct",
    //     path: "produk/:cat/:slug",
    //     component: () => import("./frontend/Product.vue"),
    // },
    {
        name: "Karir",
        path: "karir",
        component: () => import("./frontend/Karir.vue"),
    },
    {
        name: "index",
        path: "/:cat/:slug",
        component: () => import("./frontend/Home.vue"),
    },
    {
        name: "404",
        path: "*",
        component: () => import("./frontend/Page404.vue"),
    },
]
fo_router.map((v)=>{
    v.beforeEnter = (to, from, next)=>{
        if(typeof $ != 'undefined') $("html,body").scrollTop(0)
        next()
    }
})
var data = [
    {
        name: "logout",
        path: "logout",
        beforeEnter(from, to, next){
            Gen.delCookie("fotk")
            Gen.delStorage("fotk")
            if(typeof App != "undefined") App.user = null
            next({name:'index'})
        },
    },
    {
        path:"/",
        component: () => import('@/layout/FoLayout.vue'),
        children: fo_router
    },
]
export default data