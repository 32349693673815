import Config from "../config/Config";
import moment from "moment";
import env from "../env";
import asyncEval from "async-eval";

const Gen = {
  clone(data){
    return JSON.parse(JSON.stringify(data))
  },
  queryToObject(str){
    if(str.split("?").length < 2) return {}; 
    var query = str.replace("?", "").replace(/\+/g, ' ').split("&");
    query = query.map((a) => { return a += a.indexOf("=") == -1 ? "=" : "" }).join("&");
    if(!query) return {};
    return JSON.parse('{"' + decodeURIComponent(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
  },
  objectToQuery(obj) {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  },
  toObject(form){
    var data = {}
    form.serializeArray().forEach((v)=>{
      if(v.name.indexOf('[]')>-1){
        if(!data[v.name.replace("[]","")]) return data[v.name.replace("[]","")] = [v.value]
        return data[v.name.replace("[]","")].push(v.value)
      }
      data[v.name] = v.value
    })
    return data
  },
  rest(opt={}){
    if(typeof arguments[0] != "object"){
      opt = {url:arguments[0],data:arguments[1],success:arguments[2],type:arguments[3]||'get'};
    }
    if(opt.url.indexOf('http')==-1) opt.url = env.baseUrl+opt.url
    var addQuery = Object.assign({
      utoken: this.userToken()
    },this.queryToObject(opt.url))
    opt.type = opt.type.toUpperCase()
    if(opt.type=="GET"){
      opt.data = Object.assign(addQuery, opt.data)
    }else{
      opt.url += "?"+this.objectToQuery(addQuery)
      opt.data = JSON.stringify(opt.data)
      opt.contentType = "application/json; charset=utf-8"
      opt.dataType = "json"
    }
    opt.success = (resp)=>{ arguments[2](null, resp) }
    opt.error = (err)=>{ err.resp = err.responseJSON; arguments[2](err, null) }
    return $.ajax(opt)
  },
  apirest(){
    var args = arguments
    if(args[0].indexOf('http')==-1) args[0] = Config.apiUrl+args[0]
    return this.rest(args[0],args[1],args[2],args[3])
  },
  userToken(){
    return this.getSession("fotk") || this.getCookie("fotk")
  },
  putStorage(key, value){
    localStorage.setItem(key, JSON.stringify(value))
  },
  getStorage(key, def = ""){
    var item = localStorage.getItem(key)
    if(!item) return def
    return JSON.parse(localStorage.getItem(key))
  },
  delStorage(key){
    delete localStorage.removeItem(key)
  },
  putSession(key, value){
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  getSession(key, def = ""){
    var item = sessionStorage.getItem(key)
    if(!item) return def
    return JSON.parse(sessionStorage.getItem(key))
  },
  delSession(key){
    delete sessionStorage.removeItem(key)
  },
  putCookie(name,value,second) {
    var expires = "";
    if (second) {
      var date = new Date();
      date.setTime(date.getTime() + (second*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (JSON.stringify(value) || "")  + expires + "; path=/";
  },
  getCookie(name, def = "") {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0){
        var val = c.substring(nameEQ.length,c.length)
        try{
          return JSON.parse(val)
        }catch{
          return val
        }
      }
    }
    return def;
  },
  delCookie(name) {   
    document.cookie = name+'=; expires='+(new Date()).toUTCString()+'; path=/';  
  },
  info(msg, style, duration = 1000, target = '.info'){
    var alert = `<div class="alert alert-`+style+`"><button type="button" class="close" data-dismiss="alert"><span>&times;</span><span class="sr-only">Close</span></button> `+msg+`</div>`;
    var offset = $(target+":visible").offset();
    var topInfo = offset ? offset.top - 100 : 0;
    if(!$(target).parents(".modal").length && topInfo < $(window).scrollTop())
      $("html,body").animate({scrollTop:topInfo}, 300);
  
    setTimeout(function(){$(target+" div").fadeOut(300)}, duration);
    $(target).html(alert).hide().fadeIn(300);
    return new Promise((resolve)=>{ setTimeout(resolve, duration) })
  },
  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    var byteCharacters = atob(b64Data);
    var byteArrays = [];
  
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
    
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
    
      var byteArray = new Uint8Array(byteNumbers);
    
      byteArrays.push(byteArray);
    }
  
    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  },
  delay: (function () {
    var timer = 0;
    return function (callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })(),
  yearCopy(year){
    if(year != moment().year()) return year+" - "+moment().year()
    return year
  },
  loadScriptTag(tag, cache = true){
    if(window.jsTagPlugins.indexOf(tag)>-1 && cache) return;
    window.jsTagPlugins.push(tag)

    $("head").prepend(tag)
  },
  loadScript(targetPlugin, cache = true){
    var first = window.jsPlugins.filter((x)=>{ return x.src == targetPlugin})[0]
    if(first && cache){
      return new Promise((resolve)=>{
        var i = setInterval(()=>{
          if(first.ready){
            clearInterval(i)
            resolve()
          }
        }, 100);
      })
    }
    var plugin = {src:targetPlugin,ready:false}
    window.jsPlugins.push(plugin)

    return new Promise((resolve, reject)=>{
      $.ajax({
        url: (targetPlugin.indexOf("://") < 0 ? env.baseUrl : "") + targetPlugin,
        dataType: "script",
        cache: true,//This will decide whether to cache it or no so that it will not add the timestamp along with the request
        success: (script)=>{
          asyncEval(script, {}, ()=>{
            plugin.ready = true
            resolve()
          })
        },
        error:()=>{
          reject()
        }
      })
    })
  },
  loadCss(targetPlugin, position = 'bottom'){
    if(window.cssPlugins.indexOf(targetPlugin)>-1) return;
    window.cssPlugins.push(targetPlugin)

    var s = document.createElement("link");
    s.rel = "stylesheet";
    s.type = "text/css";
    s.loadcss = "true";
    s.href = (targetPlugin.indexOf("://") < 0 ? env.baseUrl : "")+targetPlugin;
    if(position=='top') $("title").after(s);
    else $("link[rel=icon]").eq(0).before(s);
  }
}

export default Gen