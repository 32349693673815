import Gen from './helper/Gen';
import BOGen from './helper/BOGen';

var bo_router = [
    {path: "dashboard", name: "BoDashboard", component:()=> import("@backend/Dashboard.vue")},
    {path: "profile", name: "BoProfile", component:()=> import("@backend/Profile.vue")},

    {path: "links/:id?", name: "BoLinks", component:()=> import("@backend/Links.vue")},
  
    /*
        Home Page
    */
    {path: "slide-show/:id?", name: "BoSlideShow", component:()=> import("@backend/SlideShow.vue")},
    {path: "intro-about/:id?", name: "BoIntroAbout", component:()=> import("@backend/IntroAbout.vue")},
    {path: "maps/:id?", name: "BoMaps", component:()=> import("@backend/Maps.vue")},
    {path: "static-home/:id?", name: "BoStaticHome", component:()=> import("@backend/StaticHome.vue")},
    /*
        About Page
    */
    {path: "value-company/:id?", name: "BoValue", component:()=> import("@backend/Value.vue")},
    {path: "our-journey/:id?", name: "BoAchievements", component:()=> import("@backend/Achievements.vue")},
    {path: "position/:id?", name: "BoEmployeePosition", component:()=> import("@backend/EmployeePosition.vue")},
    {path: "employee-team/:id?", name: "BoEmployeeTeam", component:()=> import("@backend/EmployeeTeam.vue")},
    {path: "employee-list/:id?", name: "BoEmployee", component:()=> import("@backend/Employee.vue")},
    {path: "about-achivement/:id?", name: "BoAboutAchivement", component:()=> import("@backend/AboutAchievement.vue")},
    {path: "heading-image-about/:id?", name: "BoHeadingImageAbout", component:()=> import("@backend/HeadingImageAbout.vue")},
    {path: "static-about/:id?", name: "BoStaticAbout", component:()=> import("@backend/StaticAbout.vue")},
    /*
        Product
    */
    {path: "product-category/:id?", name: "BoProduct", component:()=> import("@backend/Product.vue")},
    {path: "product/:id?", name: "BoProductDetail", component:()=> import("@backend/ProductDetail.vue")},
    {path: "heading-image-product/:id?", name: "BoHeadingImageProduct", component:()=> import("@backend/HeadingImageProduct.vue")},
    /*
        Customer Care
    */
    {path: "direct-mail/:id?", name: "BoDirectMail", component:()=> import("@backend/DirectMail.vue")},
    {path: "customer-activities/:id?", name: "BoCustomerActivities", component:()=> import("@backend/CustomerActivities.vue")},
    {path: "heading-image-customer-care/:id?", name: "BoHeadingImageCC", component:()=> import("@backend/HeadingImageCC.vue")},
    {path: "static-customer-care/:id?", name: "BoStaticCC", component:()=> import("@backend/StaticCC.vue")},
    /*
        News
    */
    {path: "journal/:id?", name: "BoJournal", component:()=> import("@backend/Journal.vue")},
    {path: "article/:id?", name: "BoArticle", component:()=> import("@backend/Article.vue")},
    {path: "category-gallery/:id?", name: "BoCategoryGallery", component:()=> import("@backend/CategoryGallery.vue")},
    {path: "gallery/:id?", name: "BoGallery", component:()=> import("@backend/Gallery.vue")},
    {path: "heading-image-news/:id?", name: "BoHeadingImageNews", component:()=> import("@backend/HeadingImageNews.vue")},
    /*
        Contact
    */
    {path: "contact-us/:id?", name: "BoContactUs", component:()=> import("@backend/ContactUs.vue")},
    {path: "sales-manager/:id?", name: "BoSalesManager", component:()=> import("@backend/SalesManager.vue")},
    {path: "heading-image-contact/:id?", name: "BoHeadingImageContact", component:()=> import("@backend/HeadingImageContact.vue")},
    /*
        Career
    */
    {path: "career/:id?", name: "BoCareer", component:()=> import("@backend/Career.vue")},
    {path: "static-career/:id?", name: "BoStaticCareer", component:()=> import("@backend/StaticCcareer.vue")},
    {path: "slider-career/:id?", name: "BoCareerSlider", component:()=> import("@backend/SliderCareer.vue")},
    {path: "testimoni-employee/:id?", name: "BoEmployeeTestimoni", component:()=> import("@backend/EmployeeTestiomoni.vue")},
    {path: "apply-form/:id?", name: "BoApplyForm", component:()=> import("@backend/ApplyForm.vue")},
    {path: "heading-image-career/:id?", name: "BoHeadingImageCareer", component:()=> import("@backend/HeadingImageCareer.vue")},
    /*
        Static
    */
    {path: "static-content/:id?", name: "BoStaticContent", component:()=> import("@backend/StaticContent.vue")},
    {path: "static-seo/:id?", name: "BoSeo", component:()=> import("@backend/Seo.vue")},
    /*
        User Management
    */
    {path: "users/:id?", name: "BoUsers", component:()=> import("@backend/Users.vue")},
    {path: "user-level/:id?", name: "BoUserLevel", component:()=> import("@backend/UserLevel.vue")},


    /* WRAP */
    // {path: "category-article/:id?", name: "BoCategoryArticle", component:()=> import("@backend/CategoryArticle.vue")},
    // {path: "sales-area/:id?", name: "BoSalesArea", component:()=> import("@backend/SalesArea.vue")},
    // {path: "hero-image/:id?", name: "BoHeroImage", component:()=> import("@backend/HeroImage.vue")},
    // {path: "web-setting/:id?", name: "BoWebSetting", component:()=> import("@backend/WebSetting.vue")},
  
    // 404
    {path: '*', redirect: {name:"BoDashboard"}},
];

var menuBypassRole = ["BoProfile"]

bo_router.map((v)=>{
    v.beforeEnter = (to, from, next)=>{
        var user = BOGen.user()
        if(!user || !Gen.getStorage('botk')) return next({name:"BoLogin"})
        if(menuBypassRole.indexOf(to.name)>-1) return next()
        if(user.bu_level==0) return next()
        if(user.menuRole.indexOf(to.name) == -1) return next({name:user.menuRole[0]})
        next()
    }
    v.meta = {bo_protected:true}
})

var data = [
    {
        name: "BoLogin",
        path: "login",
        component: () => import("@backend/Login.vue"),
        beforeEnter: (to, from, next)=>{
            var user = BOGen.user()
            if(user && Gen.getStorage('botk')) return next({name:"BoDashboard"})
            next()
        }
    },
    
    // FileManager
    {
        name: "FileManager",
        path: 'file-explore',
        component:()=> import("@components/FileManagerPage.vue")
    },
    {
        path:"",
        component: () => import('@/layout/BoLayout.vue'),
        redirect: {name:'BoDashboard'},
        children: bo_router
    },
]

export default data